import { FlexBox, TextField } from '@rario/shared-components'
import { Lineseparator } from 'components/Footer/Footer.styles'
import usePageControl from 'hooks/usePageControl'
import Link from 'next/link'

type FooterData = {
  text: string
  link: string
}
const footerSocialLinks: Array<FooterData> = [
  { text: 'Terms Of Use', link: '/terms-of-use' },
  // { text: 'Press', link: '/press' },
  { text: 'Blogs', link: '/blog' },
  { text: 'Contact Us', link: '/contact-us' },
  { text: 'Privacy Policy', link: '/privacy-policy' },
  { text: 'AML-CFT', link: '/aml-cft-policy' },
  // { text: 'Help', link: 'https://rario.freshdesk.com/support/home' },
]
const footerSocialPlatformsLinks: Array<FooterData> = [
  { text: 'Twitter', link: 'https://www.twitter.com/rariohq' },
  { text: 'LinkedIn', link: 'https://www.linkedin.com/company/rariohq/' },
  { text: 'Instagram', link: 'https://www.instagram.com/rariohq' },
  { text: 'Discord', link: 'https://discord.gg/rario' },
  { text: 'Youtube', link: 'https://www.youtube.com/channel/UCqo65RCtVWBvICDMF6COhLA/feature' },
  { text: 'Telegram', link: 'https://t.me/rariohq' },
]
const HomeLandingSocialLinkFooter: React.FunctionComponent = () => {
  const { isAboutUsPage } = usePageControl()
  return (
    <FlexBox
      alignItems={'center'}
      justifyContent={'space-between'}
      width={'100%'}
      flexWrap={'wrap-reverse'}
      border-radius={'5px'}
      mt={'30px'}
      mb={'10px'}
      px={'10px'}
    >
      <FlexBox flexDirection={'column'} flexWrap={'wrap'} width={'100%'}>
        <FlexBox
          mx={'10px'}
          flexDirection={'row'}
          flexWrap={'wrap'}
          maxHeight={'160px'}
          justifyContent={'space-around'}
          width={'95%'}
        >
          {footerSocialLinks.map((item: FooterData, index: number) => {
            if (item.text === 'Blogs') return <></>
            if (isAboutUsPage && item.text === 'About Us') return <></>
            return (
              <TextField
                key={`footerSocialLinks-${index}`}
                fontWeight={'400'}
                mb={'20px'}
                fontSize={'14px'}
                lineHeight={'28px'}
                width={'100px'}
              >
                <Link href={item.link} passHref>
                  <a>{item.text}</a>
                </Link>
              </TextField>
            )
          })}
        </FlexBox>
        <Lineseparator />
        <FlexBox
          mx={'10px'}
          flexDirection={'row'}
          flexWrap={'wrap'}
          maxHeight={'120px'}
          mt={'30px'}
          justifyContent={'space-between'}
          width={'95%'}
        >
          {footerSocialPlatformsLinks.map((item: FooterData, index: number) => {
            return (
              <TextField
                ml={'5px'}
                key={`footerSocialPlatformsLinks-${index}`}
                fontWeight="300"
                mb={'20px'}
                fontSize={'14px'}
                lineHeight={'28px'}
                opacity={'50%'}
                width={'100px'}
              >
                <a href={item.link} target="_blank" rel="noreferrer">
                  {item.text}
                </a>
              </TextField>
            )
          })}
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}

export default HomeLandingSocialLinkFooter
