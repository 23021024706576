import { Button, FlexBox, TextField } from '@rario/shared-components'
import React from 'react'
import Link from 'next/link'
import { PLATFORM_ORIGIN } from 'config'
import { ThemeEnum } from 'interfaces'
import { getCDNUrl } from 'shared-components/utils'

const HomeLandingFirstFold: React.FunctionComponent = () => {
  return (
    <FlexBox
      position={'relative'}
      flexDirection={'column'}
      zIndex={999}
      height={PLATFORM_ORIGIN === ThemeEnum.RARIO ? '580px' : '520px'}
      backgroundImage={`url(${getCDNUrl('/App/images/rario-landing/first-fold-bg-v9.png')})`}
      backgroundPosition="top"
      backgroundSize="contain"
      backgroundRepeat="no-repeat"
      justifyContent={'flex-end'}
      pb={40}
    >
      <FlexBox px={'20px'} flexDirection={'column'} textAlign={'center'} alignItems={'center'}>
        <TextField
          fontWeight="400"
          fontSize="14px"
          lineHeight="18px"
          textAlign="center"
          whiteSpace="pre-line"
          mb="20px"
        >
          Thank you all for your support throughout Rario's journey. The Buyback Program concluded
          on March 20th, 2025.{'\n'}
          {'\n'}
          Please log in to check your Vault and withdraw any balance remaining in your wallet.
        </TextField>

        <Link href={'/login'} passHref>
          <a id={'login-btn'}>
            <Button
              primaryEdges
              cornerIndentation="10px"
              name={'LOGIN'}
              mx={'12px'}
              width={'130px'}
              variant={'dynamic'}
              fontSize={'14px'}
            />
          </a>
        </Link>
      </FlexBox>
    </FlexBox>
  )
}

export default HomeLandingFirstFold
